import * as React from 'react';
import { css } from "@emotion/core";
import PropTypes from 'prop-types';

class Toast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <span
        css={css`
          position: relative;
          margin-bottom: 31px;
        `}
      >
        <h1
          css={css`
            display: inline-block;
            height: 22px;
            background-color: #12b587;
            font-family: Barlow Condensed;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #ffffff;
            width: 100%;
            margin-bottom: 20px;
            padding: 8px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            @media (min-width: 900px) {
              position: absolute;
              top: -20px;
              min-width: 250px;
              width: max-content;
              margin-left: 10px;
            }
            @media (max-width: 899px) {
              width: calc(100% - 16px);
            }
          `}
          data-testid="toast__container"
        >
          {this.props.message}
        </h1>
      </span>
    );
  }
}

Toast.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Toast;
