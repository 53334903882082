import * as React from 'react';
import { css } from "@emotion/core";
import PropTypes from 'prop-types';
import Toast from './toast';

class InternalPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        css={css`
          padding: 0;
          border: 0;
          box-shadow: 0;
          background-color: transparent;
          padding-bottom: 20px;
        `}
      >
        <div
          css={css`
            margin-bottom: 20px;
            @media (max-width: 1050px) {
              margin-bottom: 0px;
            }
          `}
        >
          {this.props.titleText ? (
            <h1
              css={css`
                display: inline;
                margin-bottom: 20px;
                @media (max-width: 767px) {
                  display: none;
                }
                height: 64px;
                font-family: Barlow Condensed;
                font-size: 32px;
                font-weight: bold;
                font-style: normal;
                font-stretch: condensed;
                line-height: 1.1;
                letter-spacing: normal;
                color: #000000;
              `}
            >
              {this.props.titleText}
            </h1>
          ) : (
            ''
          )}
          {this.props.toast ? <Toast message={this.props.toast} /> : ''}
        </div>
        {this.props.children}
      </div>
    );
  }
}

InternalPage.defaultProps = {
  children: '',
  titleText: null,
  toast: '',
};

InternalPage.propTypes = {
  children: PropTypes.node,
  titleText: PropTypes.string,
  toast: PropTypes.string,
};

export default InternalPage;
